.footer {
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  border-top: 1px solid #444;
}

.padding {
  padding-bottom: 40px;
}
