.project-card {
  display: flex;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.card-image {
  flex: 3;
  padding-right: 20px;
  height: 200px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
}

.card-content {
  flex: 5;
}

.card-content h2 {
  margin-top: 0;
}

.tag-list {
  list-style: none;
  padding: 0;
}

.tag-list li {
  display: inline-block;
  margin-right: 10px;
  background-color: #eee;
  padding: 5px 10px;
  border-radius: 4px;
}

.link-container {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.blog-link,
.demo-link {
  text-decoration: none;
  color: #007bff;
  padding: 5px 0;
  margin-bottom: 5px;
}

.blog-link:hover,
.demo-link:hover {
  text-decoration: underline;
}

.advisor-info {
  font-style: italic;
  color: #666;
  font-size: 14px;
}