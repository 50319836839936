.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-right: 10%;
}

.profile > img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-info h1,
.profile-info h2 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
