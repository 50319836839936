.App {
  display: flex;
  justify-content: center;
  align-items: start;
}

.App > div {
  width: 45%;
  margin: auto;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
}

/* Media query for tablet */
@media (max-width: 1400px) {
  .App > div {
    width: 70%;
  }
}

/* Media query for phones */
@media (max-width: 700px) {
  .App > div {
    width: 90%;
  }
}
